import React from 'react';
import PropTypes from 'prop-types';
import muro from '../images/muro-header.png';
import murowebp from '../images/muro-header.webp';
import Button from '@material-ui/core/Button';
import { Image } from "react-img-webp";
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Helmet } from "react-helmet"

const useStyles = makeStyles(theme => ({
  icon: {
    grow: 1,
    height: '60px',
    width: 'auto',
    marginRight: theme.spacing(2),
    marginBottom: '0px !important',
  },
  link:{
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '80px',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
},}));

class App extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    
    
    this.state = {sending: false, sent: false, firebase: null, name: ''};
  }

  componentDidMount() {
    let app;
    if (typeof window !== `undefined`) {
      const firebase = require("firebase/app");
      const functions = require("firebase/functions");
      app = firebase.initializeApp({
        apiKey: "AIzaSyDkCdcPa7IspA6jGGV_Xh97tL-kY1W_PKA",
        authDomain: "muro-b720d.firebaseapp.com",
        databaseURL: "https://muro-b720d.firebaseio.com",
        projectId: "muro-b720d",
        storageBucket: "muro-b720d.appspot.com",
        messagingSenderId: "954483932500",
        appId: "1:954483932500:web:7bf3196b4e246c3c"
      });
      this.setState({
        firebase: app, 
        functions: functions,
        name: '',
        email: '',
        phone: '',
        subject: ''});
    }
  }
  handleChange(event) {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    // give react a function to set the state asynchronously.
    // here it's using the "name" value set on the TextField
    // to set state.person.[firstname|lastname].   
    switch(event.target.id) {
      case 'name': {
        this.setState({name: event.target.value});
        break;
      }
      case 'email': {
        this.setState({email: event.target.value});
        break;
      }
      case 'phone': {
        this.setState({phone: event.target.value});
        break;
      }
      case 'subject': {
        this.setState({subject: event.target.value});
        break;
      }
      default:
        break;
    }       
  }
  onSubmit (event) {
    event.preventDefault();
  
    // custom form handling here
  }
  handleClick() {
    this.setState({sending: true});
    var functions = this.state.firebase.functions();
    var sendEmail = functions.httpsCallable('sendEmail');
    if (this.state.name && this.state.email && this.state.phone && this.state.subject) {
      sendEmail({name: this.state.name, email: this.state.email, phone: this.state.phone, subject: this.state.subject}).then(function(result) {
        // Read result of the Cloud Function.

        this.setState({sent: true});

      });
      this.setState({sent: true, name: '', email: '', phone: '', subject: '', sending: 'false'});
    } else {
      this.setState({sending: false});
    }

  }

  render() {
    const { classes } = this.props;
    let contactForm = (
      <form className={classes.form} onSubmit={this.onSubmit}>
            <FormControl margin="dense" fullWidth>
              <TextField ref="name" required id="name" 
              name="name" autoComplete="name" margin="normal" 
              variant="outlined" label="Nombre"
              value={this.state.name}
              onChange={this.handleChange}
              />
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <TextField value={this.state.email}
              onChange={this.handleChange} required id="email" name="email" autoComplete="email" margin="normal"  variant="outlined" label="Correo Electrónico" />
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <TextField value={this.state.phone}
              onChange={this.handleChange} required id="phone" name="phone" autoComplete="phone" margin="normal"  variant="outlined" label="Teléfono" />
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <TextField value={this.state.subject}
              onChange={this.handleChange} required id="subject" name="subject" autoComplete="subject" margin="normal"  variant="outlined" label="Asunto" />
            </FormControl>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.sending}
              onClick={this.handleClick}
            >
              Enviar
            </Button>
          </form>
    );
    if (this.state.sent) {
      contactForm = (
        <div style={{textAlign: 'center', paddingTop: '12px', paddingBottom: '12px'}}>
            <Typography>
              Su mensaje ha sido recibido.
            </Typography>
            <Typography>
           En breve lo contactaremos
          </Typography>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Muro Diseño en Tecnología Biomédica es una empresa mexicana, comprometida a la innovación en el diseño y manufactura de dispositivos médicos." />
            <title>Muro Biomédico</title>
            <link rel="canonical" href="https://murobiomedico.mx" />
          </Helmet>
        </div>
        <CssBaseline />
        <AppBar position="sticky" color="default">
          <Toolbar>
          <Image
              src={muro}
              webP={murowebp}
              className={classes.icon} alt="Muro Biomedico"
          />
            {/* <Typography className={classes.link} variant="h6" noWrap>
                Contacto
            </Typography> */}
          </Toolbar>
        </AppBar>
        <main>
          {/* Hero unit */}
          <div className={classes.heroContent}>
            <Container maxWidth="sm">
              <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Nosotros
              </Typography>
              <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Muro Diseño en Tecnología Biomédica es una empresa mexicana, comprometida a la innovación en el diseño y manufactura de dispositivos médicos. 
              </Typography>
            </Container>
          </div>
          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}
            <Grid container>
                <Grid item >
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Typography>
                          Trabajamos en conjunto con médicos especialistas mexicanos de gran trayectoria para el desarrollo de tecnología y soluciones personalizadas. Nuestra creatividad y experiencia en procesos de manufactura nos permite idear y adaptar soluciones para crear procesos de manufactura de baja escala, aumentando la factibilidad y viabilidad del desarrollo de productos médicos personalizados.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
            </Grid>
          </Container>
          <main className={classes.main}>
          <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Formulario de Contacto
          </Typography>
          {contactForm}
          </Paper>
          </main>
        </main>
        {/* Footer */}
        {/* <footer className={classes.footer}>
          <Typography variant="h6" align="center" gutterBottom>
            Footer
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            Something here to give the footer a purpose!
          </Typography>
        </footer> */}
  {/* End footer */}
      </React.Fragment>
      );
  }
}
export default function Hook() {
  const classes = useStyles();
  return <App classes={classes} />;
}